import React, { forwardRef, Ref, ReactElement, Fragment } from 'react';
import { IProductCard } from './types';
import AemLink from '@components/AemLink';
import StarRating from '@components/StarRating-2';
import Price from '@components/Price-2';
import SwatchSelector from '@components/SwatchSelector';
import Image from '@components/Image';
import { Typography } from '@components/Typography';
import { BodyStyles } from '@components/Typography/Body/types';
import { Grid } from '@components/Grid';
import styles from './styles/productCard.module.scss';
import clsx from 'clsx';

const IMAGE_SIZE = '512px';
const NUMBER_OF_SWATCHES = 4;

const ProductCard = forwardRef(
    (
        {
            SwatchesSkeleton,
            badgeText,
            dataIndex,
            dataLast,
            dataPageIndex,
            image,
            isNewVersion,
            isYotpoEnabled,
            linkProps,
            name,
            onClick,
            price,
            swatches,
            swatchesAsText,
            swatchesLoading,
            urlKey,
            yotpoRating,
            handleSwatchSelection,
        }: IProductCard,
        ref: Ref<HTMLDivElement>,
    ): ReactElement | null => {
        if (!image || !name) {
            return null;
        }

        return (
            <div
                className={clsx(styles.root, isNewVersion ? styles.newCardContainer : styles.cardContainer)}
                ref={ref}
                data-index={dataIndex}
                data-last={dataLast}
                data-mt-type="product-card"
                data-page-index={dataPageIndex}
            >
                <div className={clsx(styles.titleLink, styles.linkContainer)}>
                    <AemLink
                        className={isNewVersion ? styles.newImageContainer : styles.imageContainer}
                        onClick={onClick}
                        to={urlKey}
                        {...linkProps}
                    >
                        {isNewVersion && <span className={styles.overlayImage}></span>}
                        {badgeText && <div className={styles.badge}>{badgeText}</div>}
                        <Image
                            image={{
                                ...image,
                                dimensionsType: 'auto',
                                height: image.height || IMAGE_SIZE,
                                url: undefined,
                                width: image.width || IMAGE_SIZE,
                            }}
                            classes={{
                                image: styles.image,
                                wrapper: styles.imageWrap,
                            }}
                        />
                    </AemLink>
                    <Grid.Row className={styles.cardBody}>
                        <Grid.Col>
                            <div
                                className={clsx(
                                    styles.swatchesContainer,
                                    swatchesLoading && styles.swatchesSkeletonContainer,
                                )}
                            >
                                {swatchesLoading && !!SwatchesSkeleton ? (
                                    <SwatchesSkeleton />
                                ) : (
                                    <>
                                        {!!swatchesAsText && <Typography.Body>{swatchesAsText}</Typography.Body>}
                                        {swatches?.slice(0, NUMBER_OF_SWATCHES).map((swatch) => (
                                            <Fragment key={swatch.label}>
                                                <SwatchSelector
                                                    {...swatch}
                                                    handleSwatchSelection={handleSwatchSelection}
                                                />
                                            </Fragment>
                                        ))}
                                        {!!swatches && swatches?.length > NUMBER_OF_SWATCHES && (
                                            <div className={styles.swatchText}>
                                                <AemLink
                                                    onClick={onClick}
                                                    to={urlKey}
                                                    className={styles.titleLink}
                                                    {...linkProps}
                                                >
                                                    <Typography.Body style={BodyStyles.MINOR}>
                                                        {swatches.length - NUMBER_OF_SWATCHES}+
                                                    </Typography.Body>
                                                </AemLink>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid.Col>
                        <Grid.Col>
                            <AemLink className={styles.titleLink} onClick={onClick} to={urlKey} {...linkProps}>
                                <Typography.Body style={BodyStyles.BOLD}>{name}</Typography.Body>
                            </AemLink>
                        </Grid.Col>
                        {price && (
                            <Grid.Col>
                                <AemLink className={styles.titleLink} onClick={onClick} to={urlKey} {...linkProps}>
                                    <Price {...price} />
                                </AemLink>
                            </Grid.Col>
                        )}
                        {isYotpoEnabled && yotpoRating && (
                            <Grid.Col>
                                <AemLink className={styles.titleLink} onClick={onClick} to={urlKey} {...linkProps}>
                                    <StarRating isYotpoEnabled={isYotpoEnabled} yotpoRating={yotpoRating} />
                                </AemLink>
                            </Grid.Col>
                        )}
                    </Grid.Row>
                </div>
            </div>
        );
    },
);

ProductCard.displayName = 'ProductCard';

export default ProductCard;
